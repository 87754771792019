import api from '@/services/api'

export default {
  getAditionalContentDocs: (scheduled = false) => api.get(
    `rootCategories/${process.env.VUE_APP_DOCUMENTS_ADITIONAL_CONTENT_KEY}`,
    scheduled,
  ),
  getDocumentsByRootCategoryId: (rootCategoryId) => api.get(
    `rootCategories/${rootCategoryId}`,
  ),
  getProgramContentDocs: () => api.get(
    `rootCategories/${process.env.VUE_APP_DOCUMENTS_PROGRAM_CONTENT_KEY}`,
  ),
  getExamsAssessmentsDocs: () => api.get(
    `rootCategories/${process.env.VUE_APP_DOCUMENTS_EXAMS_ASSESSMENTS_KEY}`,
  ),
  getPraticeWritingDocs: () => api.get(
    `rootCategories/${process.env.VUE_APP_DOCUMENTS_PRATICE_WRITING_KEY}`,
  ),
  getPromotionalMaterialDocs: () => api.get(
    `rootCategories/${process.env.VUE_APP_DOCUMENTS_PROMOTIONAL_MATERIAL_KEY}`,
  ),
  getSubCategory: (rootCategoryKey, subCategoryKey) => api.get(
    `rootCategories/${rootCategoryKey}/categories/${subCategoryKey}`,
  ),
  getDocumentList: (rootCategoryKey, subCategoryKey) => api.get(
    `rootCategories/${rootCategoryKey}/categories/${subCategoryKey}/documents/findAll`,
  ),
  getRoteirosEstudo: () => api.get(
    'metadata/rootCategories?name=Tipos%20de%20materiais&value=Roteiros%20de%20Estudos%20-',
  ),
}
