<template>
  <div
    id="empty-list"
    class="empty-list"
  >
    <img
      :class="{ 'img-empty-result': imgEmptyResultClass }"
      :src="imageSrc"
    >
    <slot />
    <ContextActionsButtons
      v-if="showContextButtons"
      id="context-actions-buttons"
      :button-name="buttonName"
      :button-route-name="buttonRouteName"
      :second-button-icon="secondButtonIcon"
      :second-button-name="secondButtonName"
      size="large"
      @custom-event="$emit('custom-event')"
      @primary-event="$emit('primary-event')"
    />
  </div>
</template>

<script>
import ContextActionsButtons from '@/components/shared/ContextActionsButtons.vue'

export default {
  name: 'EmptyState',
  components: {
    ContextActionsButtons,
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    showContextButtons: Boolean,
    imgEmptyResultClass: Boolean,
    buttonName: {
      type: String,
      default: 'Novo',
    },
    buttonRouteName: {
      type: String,
      default: 'New',
    },
    secondButtonName: {
      type: String,
      default: 'Importar',
    },
    secondButtonIcon: {
      type: String,
      default: 'download',
    },
  },
}
</script>

<style lang="sass">
.empty-list
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

  .img-empty-result
    margin: 30px 0

  .empty-desc
    font-size: 16px
    line-height: 28px
    margin-top: 5px
    color: #666E75
    padding: 0 8px
    text-align: center

    &.large-margin-top
      margin-top: 30px

    +mq-m--mf
      font-size: 20px

    .highlight
      font-size: inherit
      font-weight: bold

  .actions-container-buttons
    width: 100%
    flex-direction: column-reverse
    margin-top: 35px

    +mq-m--mf
      margin-top: 95px

    button
      margin: 0

      &:first-child
        margin-top: 20px

    +mq-m--mf
      width: 530px

  .v-step
    top: -40px !important
</style>
