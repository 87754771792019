<template>
  <div class="actions-container-buttons">
    <s-button
      v-if="!hideSecondButton"
      class="second-button"
      :icon-left="secondButtonIcon"
      :size="size"
      variation="tertiary"
      @click="$emit('custom-event')"
    >
      {{ secondButtonName }}
    </s-button>

    <s-button
      class="tools-new"
      :size="size"
      @click="$emit('primary-event')"
    >
      {{ buttonName }}
    </s-button>
  </div>
</template>

<script>
export default {
  name: 'ContextActionsButtons',
  props: {
    buttonName: {
      type: String,
      default: 'Novo',
    },
    secondButtonName: {
      type: String,
      default: 'Importar',
    },
    secondButtonIcon: {
      type: String,
      default: 'download',
    },
    hideSecondButton: Boolean,
    size: {
      type: String,
      default: 'medium',
    },
  },
}
</script>

<style lang="sass">
.actions-container-buttons
  display: flex
  padding: 0 16px

  .second-button
    display: none

    +mq-m--mf
      display: flex

  .tools-new
    padding-left: 24px
    padding-right: 24px
    margin-left: 16px
    display: none

    +mq-m--mf
      display: flex

  +mq-m--mf
    padding: 0

.empty-list button
  display: flex !important

</style>
