<template>
  <div class="content-mini">
    <div :class="`card-color-mini ${cardColor}`">
      <div class="card-year">
        <h5>{{ cardYear }}</h5>
      </div>
      <div class="card-color-content">
        <div class="card-date">
          <icon
            size="15"
            type="clock"
          />
          <span class="date">{{ formattedDate }}</span>
        </div>
        <div class="card-text">
          <p>
            {{ cardTitle }}
            {{ cardEdition }} {{ cardFase }}
            {{ cardDay }} {{ cardSubject }}
          </p>
        </div>
        <div class="card-type">
          {{ cardType }}
        </div>
        <div class="card-download">
          <SButton
            download
            :href="cardDownload"
            icon-left="download"
            rel="noopener noreferrer"
            target="_blank"
            variation="link"
          >
            Baixar
          </SButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'ColorDocsMiniature',
  props: {
    cardColor: {
      type: String,
      default: '',
    },
    cardTitle: {
      type: String,
      default: '',
    },
    cardEdition: {
      type: String,
      default: '',
    },
    cardFase: {
      type: String,
      default: '',
    },
    cardDay: {
      type: String,
      default: '',
    },
    cardSubject: {
      type: String,
      default: '',
    },
    cardYear: {
      type: String,
      default: '',
    },
    cardDate: {
      type: String,
      default: '',
    },
    cardType: {
      type: String,
      default: '',
    },
    cardDownload: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      errorLoadImage: false,
    }
  },
  computed: {
    formattedDate() {
      return dayjs(this.cardDate).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="sass">
.content-mini
  display: inline-flex
  flex-direction: column

.card-color-mini
  position: relative
  width: 245px
  height: 303px
  background: #5B7A95
  box-shadow: 0px 0.5px 6px rgba(0, 0, 0, 0.12)
  border-radius: 16px
  background-image: url('~@/assets/img/cardBackground.svg')
  background-position: center
  background-repeat: no-repeat
  background-size: auto

.default
  background: #5B7A95

.card-color-content
  height: 250px
  width: 245px
  position: absolute
  bottom: 0
  padding: $size-m
  background: #FFFFFF
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.2)
  border-radius: 0px 30px 16px 16px
  display: flex
  flex-direction: column

.card-year
  color: #FFFFFF
  padding: 15px

.card-date
  color: #666E75

.date
  position: relative
  top: -3px
  margin-left: 5px
  font-weight: normal
  font-size: 10px
  line-height: 18px

.card-text
  color: #003064
  font-weight: 800
  font-size: 14px
  line-height: 20px
  margin-top: 10px

.card-type
  color: #1E2124
  font-weight: 500
  font-size: 16px
  line-height: 24px
  margin-top: 16px
  margin-bottom: 4px
  flex: 1

.card-download
  color: #0467DB
  font-weight: 800
  font-size: 14px
  line-height: 20px
</style>
