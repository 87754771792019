import urls from './urls'

export const getFileFullUrl = (fileLink) => {
  if (!fileLink || fileLink.startsWith('http')) {
    return fileLink
  }

  return `${urls.DOWNLOAD_FILES_API}/${fileLink}`
}

export default { getFileFullUrl }
